import React from 'react';

const Modal = ({ photo, onClose, onNext, onPrev }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex flex-col justify-between z-50">
            {/* Header with close button */}
            <div className="flex justify-end items-center px-6 py-4 bg-black bg-opacity-80">
                <button
                    className="text-white text-2xl focus:outline-none"
                    onClick={onClose}
                    style={{ padding: '12px' }} // Add padding to the close button
                >
                    X
                </button>
            </div>

            {/* Body with previous, content, and next */}
            <div className="flex items-center justify-between flex-grow">
                {/* Previous button */}
                <button
                    className="text-white text-2xl focus:outline-none p-4"
                    onClick={onPrev}
                >
                    &lt; Prev
                </button>

                {/* Image content */}
                <div className="flex-grow flex justify-center items-center px-4">
                    <img
                        src={photo.src}
                        alt={photo.alt}
                        className="max-h-screen object-contain"
                    />
                </div>

                {/* Next button */}
                <button
                    className="text-white text-2xl focus:outline-none p-4"
                    onClick={onNext}
                >
                    Next &gt;
                </button>
            </div>

            {/* Footer with title and button */}
            <div className="flex justify-center items-center px-6 py-4 bg-black bg-opacity-80">
                <p className="text-white text-center">{photo.alt}</p>
            </div>
        </div>
    );
};

export default Modal;
