import React from 'react';

const AboutPage = () => {
    return (
        <div className="container mx-auto px-4 py-12">
            {/* Intro Section */}
            <div className="text-center mb-12">
                <h1 className="text-4xl font-semibold mb-4">Philippine Facade Forum</h1>
                <p className="text-gray-700">
                    Philippine Facade Forum is an avenue for facade engineering professionals in the Philippines,
                    both on design and construction to share, discuss and develop knowledge and useful resources within the industry.
                </p>
            </div>

            {/* Founders Section */}
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
                {/* Founder 1 */}
                <div className="flex flex-col md:flex-row items-center">
                    <img
                        src="/Edwin-Agustin_edited.webp"
                        alt="Edwin Agustin - Founder"
                        className="w-48 h-48 rounded-full object-cover mb-4 md:mb-0 md:mr-6"
                    />
                    <div>
                        <h2 className="text-2xl font-semibold mb-2">Edwin Agustin - Founder</h2>
                        <h3 className="text-gray-600 mb-4">Vice President - Construction Administration, ALT Cladding</h3>
                        <p className="text-gray-700">
                            Edwin has more than 20 years experience in the facade industry. Over the years, he is trained to master the skills
                            required in construction administration especially quality control on and off-site, review and approval of materials and
                            shop drawings, and mock-up test witnessing.
                            <br /><br />
                            Among his remarkable projects are the Burj Khalifa in Dubai, MGM City Center, Mandarin Oriental Hotel and Veer Towers in the USA,
                            Landmark in London, UK and various prestigious projects in China, Singapore, and the Philippines.
                            <br /><br />
                            Before joining ALT, he has worked with facade contractors as chief quality administrator, and his connections with several
                            construction management companies gained him extensive knowledge in construction management.
                        </p>
                    </div>
                </div>

                {/* Founder 2 */}
                <div className="flex flex-col md:flex-row items-center">
                    <img
                        src="/jack_edited.webp"
                        alt="Jack Cruzado - Founder"
                        className="w-48 h-48 rounded-full object-cover mb-4 md:mb-0 md:mr-6"
                    />
                    <div>
                        <h2 className="text-2xl font-semibold mb-2">Jack Cruzado - Founder</h2>
                        <h3 className="text-gray-600 mb-4">Principal - Facade Option</h3>
                        <p className="text-gray-700">
                            Jack has almost 13 years experience in the facade industry. Over the years, he works for facade consultants, contractors,
                            and sealant manufacturing companies, with work experience in different countries like Singapore, Hong Kong, Thailand, and the Philippines.
                            <br /><br />
                            Among his remarkable project involvements are The Star Vista and Marina Business Financial Center in Singapore,
                            West Kowloon Terminus, New World Center, and Hong Kong International Airport Midfield Concourse in Hong Kong.
                        </p>
                    </div>
                </div>
            </div>

            {/* Contact Section */}
            <div className="text-center mt-12">
                <p className="text-gray-600">Contact us @ Email: info@phfacade.com</p>
            </div>
        </div>
    );
};

export default AboutPage;
