import React, { useEffect, useState } from 'react';
import WebinarSection from "../components/WebinarSection";
import PHProjectPhotos from "../components/PHProjectPhotos";
import FacadeSystemPhoto from "../components/FacadeSystemPhoto";
import { useLocation } from 'react-router-dom';

const HomePage = () => {
    const location = useLocation();
    const [activeSection, setActiveSection] = useState('');

    // Scroll to section based on hash in URL
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    // Track active section for navigation
    useEffect(() => {
        const sections = document.querySelectorAll("section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            {
                threshold: 0.7, // Trigger when 70% of the section is visible
            }
        );

        sections.forEach((section) => {
            observer.observe(section);
        });

        return () => {
            sections.forEach((section) => observer.unobserve(section));
        };
    }, []);

    return (
        <div className="content mx-auto">
             <section id="home">
   <div className="text-center max-w-4xl mx-auto mb-12 mt-12">
        <p className="text-lg font-medium text-gray-700 leading-relaxed">
            <span className="font-semibold text-gray-900">The Premier Online Destination for Facade Engineering Professionals in the Philippines.</span>
        </p>
        <p className="text-lg text-gray-600 mt-4">
            At the <span className="font-bold text-gray-800">Philippine Facade Association</span>, we bring together designers, constructors, and industry experts to explore, discuss, and advance the art and science of facade engineering.
        </p>
    </div>
            {/* Section for Webinar */}

                <WebinarSection />
            </section>
                <section id="facade-system-photos">
                <FacadeSystemPhoto />
            </section>

            {/* Section for PH Project Photos */}
            <section id="ph-project-photos">
                <PHProjectPhotos />
            </section>

            {/* Section for Facade System Photos */}

        </div>
    );
};

export default HomePage;
