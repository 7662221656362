import React, { useState } from 'react';
import Modal from './Modal'; // Import the Modal component

const PHProjectPhotos = () => {
    // Sample photos array, replace with actual paths and captions
    const photos = [
        { src: '/phproject/1.webp', alt: 'SM Keppel Tower' },
        { src: '/phproject/2.webp', alt: 'W City Center BGC' },
        { src: '/phproject/3.webp', alt: 'Ayala Triangle Gardens' },
        { src: '/phproject/4.webp', alt: 'Net Lima' },
        { src: '/phproject/5.webp', alt: 'Conrad Hotel Moa' },
        { src: '/phproject/7.webp', alt: 'Philippine Stock Exchanges BGC' },
        { src: '/phproject/8.webp', alt: 'Zuellig Building' },
        { src: '/phproject/9.webp', alt: 'RCBC Plaza' },
    ];

    const [currentIndex, setCurrentIndex] = useState(null);

    // Open modal and set the current photo
    const openModal = (index) => {
        setCurrentIndex(index);
    };

    // Close modal
    const closeModal = () => {
        setCurrentIndex(null);
    };

    // Show the next photo
    const showNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    // Show the previous photo
    const showPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    return (
        <div className="container mx-auto px-4 py-12">
            {/* Title */}
            <h2 className="text-3xl font-semibold text-center mb-8">PH Project Photos</h2>

            {/* Photo Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {photos.map((photo, index) => (
                    <div key={index} className="flex flex-col items-center cursor-pointer" onClick={() => openModal(index)}>
                        <div className="bg-gray-200 w-full h-64 flex items-center justify-center">
                            {/* Replace src with actual image links */}
                            <img src={photo.src} alt={photo.alt} className="w-full h-full object-cover" />
                        </div>
                        <p className="mt-2 text-center">{photo.alt}</p>
                    </div>
                ))}
            </div>

            {/* Modal */}
            {currentIndex !== null && (
                <Modal
                    photo={photos[currentIndex]}
                    onClose={closeModal}
                    onNext={showNext}
                    onPrev={showPrev}
                />
            )}


        </div>
    );
};

export default PHProjectPhotos;
