import React from 'react';

const WebinarSection = () => {
    return (
        <div className="container mx-auto px-4 py-12">
            {/* Title */}
            <h2 className="text-3xl font-semibold text-center mb-8">FACADE 101 - Webinar</h2>

            {/* Video Thumbnails */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* Part 1 */}
                <div className="flex flex-col items-center">
                    <div className="bg-gray-200 w-full h-56 flex items-center justify-center">
                        {/* Replace src with actual video link */}
                        <video
                            className="w-full h-full object-cover"
                            controls
                        >
                            <source src="/FACADE 101_Part 2-edit.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <p className="mt-2 text-center">Part 1 - Systems / Terminology</p>
                </div>

                {/* Part 2 */}
                <div className="flex flex-col items-center">
                    <div className="bg-gray-200 w-full h-56 flex items-center justify-center">
                        <video
                            className="w-full h-full object-cover"
                            controls
                        >
                            <source src="/path/to/video2.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <p className="mt-2 text-center">Part 2 - Material / Performance</p>
                </div>

                {/* Part 3 */}
                <div className="flex flex-col items-center">
                    <div className="bg-gray-200 w-full h-56 flex items-center justify-center">
                        <video
                            className="w-full h-full object-cover"
                            controls
                        >
                            <source src="/FACADE 101_PArt 3-edit.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <p className="mt-2 text-center">Part 3 - Facade Testing</p>
                </div>
            </div>
        </div>
    );
};

export default WebinarSection;
