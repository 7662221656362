import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ activeSection }) => {
    const location = useLocation();

    // Helper function to check if the section is active
    const isActive = (section) => location.hash === `#${section}` || activeSection === section;

    return (
        <header className="bg-white shadow">
            <div className="container mx-auto px-4 py-4 flex items-center justify-between">
                {/* Logo on the left */}
                <div className="flex items-center">
                    <img src="/phfacade.gif" alt="Philippine Facade Forum Logo" className="h-24 m-2" />
                    <h1 className="text-xl">Philippine Facade Forum</h1>
                </div>

                {/* Navigation on the right */}
                <nav>
                    <ul className="flex space-x-6 text-sm">
                        <li>
                            <Link
                                to="/#home"
                                className={`${
                                    isActive('home') ? 'text-green-500' : 'text-gray-700'
                                } hover:text-green-600`}
                            >
                                Home
                            </Link>
                        </li>
                          <li>
                            <Link
                                to="/#facade-system-photos"
                                className={`${
                                    isActive('facade-system-photos') ? 'text-green-500' : 'text-gray-700'
                                } hover:text-green-600`}
                            >
                                Facade System Photos
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/#ph-project-photos"
                                className={`${
                                    isActive('ph-project-photos') ? 'text-green-500' : 'text-gray-700'
                                } hover:text-green-600`}
                            >
                                PH Project Photos
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/about"
                                className={`${
                                    location.pathname === '/about' ? 'text-green-500' : 'text-gray-700'
                                } hover:text-green-600`}
                            >
                                About
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
