import React, { useState } from 'react';
import Modal from './Modal'; // Import the Modal component

const FacadeSystemPhoto = () => {
    // Array of image data for the grid
    const photos = [
        { src: '/systemfacade/1.webp', alt: 'Project 1' },
        { src: '/systemfacade/2.webp', alt: 'Project 2' },
        { src: '/systemfacade/3.webp', alt: 'Project 3' },
        { src: '/systemfacade/4.webp', alt: 'Project 4' },
        { src: '/systemfacade/5.webp', alt: 'Project 5' },
        { src: '/systemfacade/6.webp', alt: 'Project 6' },
        { src: '/systemfacade/7.webp', alt: 'Project 7' },
        { src: '/systemfacade/8.webp', alt: 'Project 8' },
        { src: '/systemfacade/9.webp', alt: 'Project 9' },
        { src: '/systemfacade/10.webp', alt: 'Project 10' },
        { src: '/systemfacade/11.webp', alt: 'Project 11' },
        { src: '/systemfacade/12.webp', alt: 'Project 12' },
        { src: '/systemfacade/13.webp', alt: 'Project 13' },
        { src: '/systemfacade/14.webp', alt: 'Project 14' },
        { src: '/systemfacade/15.webp', alt: 'Project 15' },
        { src: '/systemfacade/16.webp', alt: 'Project 16' },
        { src: '/systemfacade/17.webp', alt: 'Project 17' },
        { src: '/systemfacade/18.webp', alt: 'Project 18' },
    ];

    const [currentIndex, setCurrentIndex] = useState(null);

    // Open modal and set the current photo
    const openModal = (index) => {
        setCurrentIndex(index);
    };

    // Close modal
    const closeModal = () => {
        setCurrentIndex(null);
    };

    // Show the next photo
    const showNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    // Show the previous photo
    const showPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    return (
        <div className="container mx-auto px-4 py-12">
            {/* Title */}
            <h2 className="text-3xl font-semibold text-center mb-8">Facade System Photos</h2>

            {/* Photo Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {photos.map((photo, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center cursor-pointer"
                        onClick={() => openModal(index)}
                    >
                        <div className="bg-gray-200 w-full h-64 flex items-center justify-center">
                            <img src={photo.src} alt={photo.alt} className="w-full h-full object-cover" />
                        </div>
                        <p className="mt-2 text-center">{photo.alt}</p>
                    </div>
                ))}
            </div>

            {/* Modal */}
            {currentIndex !== null && (
                <Modal
                    photo={photos[currentIndex]}
                    onClose={closeModal}
                    onNext={showNext}
                    onPrev={showPrev}
                />
            )}
        </div>
    );
};

export default FacadeSystemPhoto;
